
import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["source"]
	copy() {
		var tempInput = document.createElement("input");
		tempInput.value = this.sourceTarget.innerHTML;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		console.log("ndc " + tempInput.value + " copied to clipboard");
		document.body.removeChild(tempInput);
	}
}